import { Injectable } from '@angular/core';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private readonly utilsService: UtilService) {}

  public setCookie(key: string, data: any, expirationInSecs?: number) {
    if (this.utilsService.isBrowser()) {
      const expiration = typeof expirationInSecs !== 'undefined' ? new Date(new Date().getTime() + expirationInSecs * 1000) : null;
      const stringifiedData = typeof data === 'string' ? data : JSON.stringify(data);
      document.cookie = `${key}=${stringifiedData}; path=/; ${expiration ? `expires=${expiration.toUTCString()}` : ''}`;
    }
  }

  public getCookie(key: string, cookies: string, defaultValue: any = null): string {
    const value = `; ${cookies}`;
    const parts = value.split(`; ${key}=`);
    return parts.length === 2 ? parts.pop()?.split(';').shift() : defaultValue;
  }

  public setSessionStorageData(key: string, data: any) {
    if (this.utilsService.isBrowser()) {
      sessionStorage.setItem(key, JSON.stringify(data));
    }
  }

  public getSessionStorageData(key: string, defaultValue: any = null) {
    if (this.utilsService.isBrowser()) {
      const savedData = JSON.parse(sessionStorage.getItem(key) || '');
      return savedData || defaultValue;
    }
    return null;
  }

  public setLocalStorageData(key: string, data: any) {
    if (this.utilsService.isBrowser()) {
      localStorage.setItem(key, JSON.stringify(data));
    }
  }

  public getLocalStorageData(key: string, defaultValue: any = null) {
    if (this.utilsService.isBrowser()) {
      const savedData = JSON.parse(localStorage.getItem(key) || '');
      return savedData || defaultValue;
    }
    return null;
  }
}
