import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, PLATFORM_ID } from '@angular/core';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable, Observer } from 'rxjs';
import { environment } from 'src/environments/environment';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const fs = require('fs');

export class TranslateUniversalLoader implements TranslateLoader {
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly transferState: TransferState,
    private readonly http: HttpClient
  ) {}

  getTranslation(lang: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      const key: StateKey<any> = makeStateKey<any>(`transfer-translate-${lang}`);

      let localesFolder = '';
      if (isPlatformBrowser(this.platformId)) {
        localesFolder = environment.translation && environment.translation.localesFolder.client;
      } else {
        localesFolder = __dirname + (environment.translation && environment.translation.localesFolder.server);
      }

      // Node oldalon olvassa be a fordítókulcsokat a JSON-ből és mentse el TransferState-ben
      if (isPlatformServer(this.platformId)) {
        const path = `${localesFolder}/${lang}.json`;
        const translation = fs.existsSync(path) ? JSON.parse(fs.readFileSync(path, 'utf8')) : {};
        this.transferState.set(key, translation);
        observer.next(translation);
        observer.complete();
      } else {
        const translationFromServer = this.transferState.get(key, null);
        // Ha lett elmentve fordítás a TransferState-ben, akkor böngésző oldalon szedje ki onnan a fordításokat
        if (translationFromServer && Object.keys(translationFromServer).length) {
          observer.next(translationFromServer);
          observer.complete();
          // Ha nem, akkor kérje le őket a JSON fájlból HTTP hívással
        } else {
          new TranslateHttpLoader(this.http, `${localesFolder}/`, '.json').getTranslation(lang).subscribe(
            (translation: any) => {
              observer.next(translation);
              observer.complete();
            },
            () => {
              observer.next({});
              observer.complete();
            }
          );
        }
      }
    });
  }
}

export function translateLoader(platformId: any, transferState: TransferState, http: HttpClient) {
  return new TranslateUniversalLoader(platformId, transferState, http);
}
