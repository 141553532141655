import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, InjectionToken, PLATFORM_ID, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BrowserType, LogType } from '../interfaces/utils.interface';

declare let window: any;
declare let InstallTrigger: any;
declare let safari: any;

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: InjectionToken<Object>,
    private readonly sanitizer: DomSanitizer,
  ) {}

  // Ha nincs megadva type, visszaadja hogy böngésző oldalon vagyunk-e.
  // Ha van megadva type, akkor pedig hogy az adott böngésző típuson vagyunk-e
  public isBrowser(type?: BrowserType): boolean {
    const isBrowser = isPlatformBrowser(this.platformId);
    if (type && isBrowser) {
      switch (type) {
        // Opera 8.0+
        case 'opera':
          return (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        // Firefox 1.0+
        case 'firefox':
          return typeof InstallTrigger !== 'undefined';
        // Safari 3.0+
        case 'safari':
          return (
            /constructor/i.test(window.HTMLElement) ||
            (function (p) {
              return p.toString() === '[object SafariRemoteNotification]';
            })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))
          );
        // Internet Explorer 6-11
        case 'ie':
          return /*@cc_on!@*/ false || !!(<any>document).documentMode;
        // Edge 20+
        case 'edge':
          return !(/*@cc_on!@*/ (false || !!(<any>document).documentMode)) && !!window.StyleMedia;
        // Chrome 1 - 79
        case 'chrome':
          return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        // Edge (based on chromium) detection
        case 'edge-chromium':
          return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime) && navigator.userAgent.indexOf('Edg') !== -1;
      }
    }
    return isBrowser;
  }

  public log(message: string, type: LogType = 'info') {
    if (this.isBrowser()) {
      switch (type) {
        case 'info':
          console.log(`%c ${message}`, 'color: blue; background: #cce5ff; display: block; padding: 2px');
          break;
        case 'success':
          console.log(`%c ${message}`, 'color: green; background: #d4edda; display: block; padding: 2px');
          break;
        case 'warning':
          console.warn(message);
          break;
        case 'error':
          console.error(message);
          break;
        default:
          console.log(message);
          break;
      }
    } else {
      console.log(message);
    }
  }

  // átalakítja a YouTube videó linket beágyazható formátumra
  public convertYouTubeLinkToEmbed(link: string): string {
    if (link) {
      const embededLink = link.replace('watch?v=', 'embed/');
      const resourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embededLink);
      return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, resourceUrl) || '';
    }
    return '';
  }
}
