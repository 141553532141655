<footer>
  <div class="wrapper">
    <div class="left">© Metropol, 2022. Minden Jog Fenntartva.</div>
    <div class="mid">
      <a class="logo" href="https://metropol.hu/" target="_blank">
        <img class="logo-image" src="/assets/images/metropollogo.png" />
      </a>
    </div>
    <div class="right">
      <a class="footer-link" href="https://metropol.hu/impresszum/" target="_blank">Impresszum</a>
      <a
        class="footer-link"
        href="https://metropol.hu/wp-content/uploads/2021/10/3087927metropoladatvedelmitajekoztato20211001.pdf"
        target="_blank"
        >Adatvédelmi tájékoztató</a
      >
    </div>
  </div>
</footer>
