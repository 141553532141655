<header>
  <div class="wrapper">
    <div class="left">
      <a class="logo" target="_blank" href="https://metropol.hu/">
        <img class="logo-image" src="/assets/images/metropollogo.png" />
        <p class="title">Budapest korrupciós térkép</p>
      </a>
    </div>
    <div class="right">
      <a class="social-link" href="https://metropol.hu/hirlevel-feliratkozas/">
        <i class="icon icon-envelop"></i>
      </a>
      <a class="social-link" href="https://www.facebook.com/Metropol.Napilap" target="_blank">
        <i class="icon icon-facebook"></i>
      </a>
      <a class="social-link" href="https://www.instagram.com/metropol.napilap/" target="_blank">
        <i class="icon icon-instagram"></i>
      </a>
    </div>
  </div>
</header>
