import { IEnvironment } from './environment.definitions';

// Lokális fejlesztői környezet
export const environment: IEnvironment = {
  production: false,
  type: 'local',
  translation: {
    defaultLang: 'hu',
    locales: ['hu', 'en'],
    prefix: 'ROUTES.',
    localesFolder: {
      client: '/assets/locales',
      server: '/../browser/assets/locales',
    },
  },
};
