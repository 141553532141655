import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule, TransferState } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { CoreModule } from './core/core.module';
import { localizeLoader } from './utils/translation/localize-loader';
import { translateLoader } from './utils/translation/translate-loader';
import { TrendencyHttpInterceptor } from './utils/interceptors/http.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    BrowserTransferStateModule,
    RouterModule.forRoot(appRoutes, {
      initialNavigation: 'disabled',
      relativeLinkResolution: 'legacy',
    }),
    TranslateModule.forRoot({
      defaultLanguage: environment.translation.defaultLang,
      loader: {
        provide: TranslateLoader,
        useFactory: (translateLoader),
        deps: [PLATFORM_ID, TransferState, HttpClient],
      },
    }),
    LocalizeRouterModule.forRoot(appRoutes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (localizeLoader),
        deps: [TranslateService, Location, LocalizeRouterSettings],
      },
      alwaysSetPrefix: false,
      defaultLangFunction: () => environment.translation.defaultLang,
      initialNavigation: true
    }),
    CoreModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TrendencyHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
